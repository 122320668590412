import Link from "next/link";
import Image from "next/image";
import React, { useRef, useState } from "react";
import CountdownBar from "../CountdownBar";
import SocialMedia from "../SocialMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "../../styles/Home.module.scss";
import { useTranslation } from "next-i18next";
import parse from "html-react-parser";
import { useRegionInfo, useNewsInfo } from "../../services/apiHooks";
import { useRouter } from "next/router";
import moment from "moment";
import WeatherWidget from "@components/weather/WeatherWidget";
import {registerRequest} from "@services/azure/authConfig";
import {useMsal} from "@azure/msal-react";

const slickSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

let currentAnimation;

export default function HomePage(props) {
    const router = useRouter();
    const { instance } = useMsal();
    const { weather } = useRegionInfo(router.locale);
    const { news } = useNewsInfo(router.locale);
    const { t } = useTranslation("common");
    const [mouseMoved, setMouseMoved] = useState(false);
    const sliderRef = useRef();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(registerRequest).catch((e) => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(registerRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    React.useEffect(() => {
        import("lottie-interactive/dist/lottie-interactive.js");
    });

    const onNewsClick = (link) => {
        if (!mouseMoved) window.open(link);
    };

    const weatherInfo = {
        phrase: weather?.results[0].phrase,
        iconCode: weather?.results[0].iconCode,
        temperature: Math.round(weather?.results[0].temperature.value),
        relativeHumidity: Math.round(weather?.results[0].relativeHumidity),
        wind: Math.round(weather?.results[0].wind.speed.value),
    };

    const { phrase, iconCode, temperature, relativeHumidity, wind } = weatherInfo;

    switch (iconCode) {
        case 1: /* Sunny */
        case 2 /* Mostly Sunny */:
            currentAnimation = "/assets/lottie/sun.json";
            break;

        case 3: /* Partly Sunny */
        case 4: /* Intermittent Clouds */
        case 5 /* Hazy Sunshine */:
            currentAnimation = "/assets/lottie/sun-and-cloud.json";
            break;

        case 6 /* Mostly Cloudy */:
        case 7: /* Cloudy */
        case 8: /* Dreary */
        case 11 /* Fog */:
            currentAnimation = "/assets/lottie/cloud.json";
            break;

        case 12: /* Showers */
        case 13: /* Mostly Cloudy with Showers */
        case 14: /* Partly Sunny with Showers */
        case 18 /* Rain */:
            currentAnimation = "/assets/lottie/showers.json";

        default:
            currentAnimation = "/assets/lottie/sun-and-cloud.json";
    }

    return (
      <>
        <section className={`${styles.banner} banner d-flex align-items-center`}>
          <div className="d-none d-md-block">
            <Image
              src="/assets/images/banners/home-banner.png"
              alt="home-img"
              layout="fill"
              objectFit="cover"
            />
            <div className="banner-overlay" width="100%" height="100%"></div>
          </div>
          <div className="d-md-none">
            <Image
              src="/assets/images/banners/home-banner.png"
              alt="home-img"
              layout="fill"
              objectFit="cover"
            />
            <div className="banner-overlay" width="100%" height="100%"></div>
          </div>
          <div className="container flex-column padding-y-10 position-relative my-auto banner-text d-flex justify-content-between banner-content align-items-start">
            <div className={`${styles.content} col-md-6 `}>
              <h5 className="text-white">{t("welcometext")}</h5>
              <h1 className="text-white">{t("hayyatext")}</h1>
            </div>
            <div className=" col-md-7 home-content">
              <p className="text-white mt-5">{t("homepagedesc")}</p>
              <button
                className="banner-btn mt-3"
                onClick={() => handleLogin("redirect")}
              >
                {" "}
                {t("applyPermitsBtn")}
              </button>
            </div>
          </div>
        </section>
        <div></div>
        <section className={`${styles.welcomeText} py-sm-5 py-3 mt-sm-5 my-3`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 door-img">
                <div className="relative door-img-inner">
                  <Image
                    width={130}
                    height={100}
                    alt="media icon"
                    src={"https://stgcms.hayya.qa/wp-content/uploads/2024/11/media.png"}
                    className="sect-img"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="">
                  <p>{t("homepagepara1")}</p>
                  <p className="compact-text">{t("homepagepara2")}</p>
                </div>
                <p>
                  {parse(t("hostcountryparagraph6"))}&nbsp;
                  <a href="mailto:media@hayya.qa" className="link">
                    {t("hostcountryparagraph6-1")}
                  </a>
                  &nbsp;
                  {t("hostcountryparagraph6-2")}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className={`${styles.welcomeText} py-sm-5 py-3 mt-sm-5 my-3`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 door-img"><img src="/assets/images/doors.png" className="sect-img"/></div>
            <div className="col-md-6">
            <div> <h1>{t("welcometext")}</h1>
          <h3 className="py-3">{t("hostcountrytext")}</h3>
          <p>
            {t("hostcountryparagraph1")}&nbsp;
            {t("hostcountryparagraph2")}{" "}
            <Link href="/accreditation&permits">
              <a> {t("hostcountryparagraph3")}</a>
            </Link>{" "}
            {t("hostcountryparagraph4")}&nbsp;
            <Link href="/accreditation&permits">
              <a>{t("hostcountryparagraph4-0")} </a>
            </Link>{" "}
            {t("hostcountryparagraph5")}
            {t("hostcountryparagraph5-0")}
          </p>
          <p>
            {parse(t("hostcountryparagraph6"))}&nbsp;
            <a href="mailto:media@hayya.qa">{t("hostcountryparagraph6-1")}</a>
            &nbsp;
            {t("hostcountryparagraph6-2")}
          </p>
          </div></div></div>
         </div>
      </section> */}

        {/* <CountdownBar /> */}
        {/* <section className={`news bg-light-grey  pt-4`}>
        <div className="container position-relative">
          <div className="d-flex justify-content-between">
            <h1 className="pt-5 text-burgundy fw-bold ff-qatar2022">
              {t("newstitle")}
            </h1>
            <div className="my-md-3 position-absolute end-0 me-3 ms-ar-3 start-ar-0">
              <div className={`${styles.lottieWrapper}`}>
                <lottie-interactive
                  path="/assets/lottie/circle-and-arrow1.json"
                  interaction="play-on-show"
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <a
              href={t("seemorelink")}
              target="_blank"
              className="ms-auto me-ar-auto text-orange small fw-medium ff-graphik me-2"
            >
              {t("seemore")}
            </a>
            <div className={`customNavigation mb-3`}>
              <button
                type="button"
                data-role="none"
                className={` customArrow prev`}
                onClick={() => sliderRef?.current.slickPrev()}
              >
                Previous
              </button>
              <button
                type="button"
                data-role="none"
                className={`customArrow next`}
                onClick={() => sliderRef?.current.slickNext()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div>
          <Slider {...slickSettings} ref={sliderRef}>
            {news?.map(({ id, title, author, link, image, date }) => (
              <div
                key={id}
                className={` slide position-relative`}
                onMouseMove={() => setMouseMoved(true)}
                onMouseDown={() => setMouseMoved(false)}
                onMouseUp={onNewsClick.bind(this, link)}
              >
                <Image
                  alt="news-a-img"
                  src={image}
                  width={631}
                  height={631}
                  layout="responsive"
                />

                <div className={`contentText`}>
                  <h3>{title}</h3>
                  <p>{author}</p>
                  <p className={`dateNews newstitle`}>
                    {moment(date).format("DD MMMM YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <SocialMedia
        Color="#8a1538"
        colNumber="col-md-4 col-12"
        font="ff-qatar2022 fs-5"
        fontWeight="fw-medium"
        textColor="text-burgundy"
        background="bg-light-grey"
        display="flex-row"
      />
      <WeatherWidget /> */}
      </>
    );
}
